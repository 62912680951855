@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?6uexrp');
  src:  url('../fonts/icomoon.eot?6uexrp#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?6uexrp') format('truetype'),
    url('../fonts/icomoon.woff?6uexrp') format('woff'),
    url('../fonts/icomoon.svg?6uexrp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-refund3:before {
  content: "\e900";
  color: #a4a7b0;
}
.icon-icon-upload:before {
  content: "\e901";
  color: #a4a7b0;
}
.icon-icon-refund2:before {
  content: "\e902";
  color: #a4a7b0;
}
.icon-icon-edit:before {
  content: "\e903";
  color: #a4a7b0;
}
.icon-icon-close2:before {
  content: "\e904";
  color: #fff;
}
.icon-icon-tick:before {
  content: "\e905";
  color: #fff;
}
.icon-icon-block:before {
  content: "\e906";
  color: #a4a7b0;
}
.icon-icon-lock:before {
  content: "\e907";
  color: #a4a7b0;
}
.icon-icon-down-arrow:before {
  content: "\e908";
  color: #a4a7b0;
}
.icon-icon-calander:before {
  content: "\e909";
  color: #a4a7b0;
}
.icon-icon-school:before {
  content: "\e90a";
}
.icon-icon-bag:before {
  content: "\e90b";
}
.icon-icon-bill:before {
  content: "\e90c";
}
.icon-icon-close:before {
  content: "\e90d";
  color: #a4a7b0;
}
.icon-icon-filter:before {
  content: "\e90e";
  color: #a4a7b0;
}
.icon-icon-search:before {
  content: "\e90f";
  color: #fff;
}
.icon-icon-settings:before {
  content: "\e910";
  color: #a4a7b0;
}
.icon-icon-delete:before {
  content: "\e911";
  color: #a4a7b0;
}
.icon-icon-download:before {
  content: "\e912";
  color: #a4a7b0;
}
.icon-icon-refund:before {
  content: "\e913";
  color: #a4a7b0;
}
